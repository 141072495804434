@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --poppins-font: 'Poppins', sans-serif;
    --primary-color: #001f3f; 
    --secondary-color: #FF4136;
    --accent-color: #FF851B; 
    --background-color: #111111; 
    --text-color: #EEEEEE; 
    --glow-color: hsl(0, 75%, 45%);
}

.about-title {
    font-family: var(--poppins-font);
    color: var(--accent-color);
}

.app.about{
    padding: 15px;
    padding-left: 30px;
}

.aboutContainer {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    margin-top: 100px;
}

.about-list > li{
    list-style-type: circle;
    color: var(--text-color);
}