.form-group-profile {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-group-profile.hidden {
  display: none;
}

label {
  display: block;
  font-weight: bold;
  color: #fff; /* White text color */
}


.reg-log {
  display: none;
}

.construction {
  color: var(--accent-color);
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 60px;
  margin-bottom: -80px;
}

.content-thumbnail.fav{
  perspective: 1000px;
  height: 520px;
  width: 390px;
  padding: auto;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.card-3d-wrap.profile {
  margin-bottom: 120px;
}


.card-front-profile {
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover; /* Makes the image cover the entire container */
  background-repeat: no-repeat; /* Prevents image repetition */
  background-position: center center;
  background-image: url("https://drive.google.com/uc?export=download&id=100-rCqMFQPyhITcr2US8YokiMn5G5D0U");
  position: absolute;
  border-radius: 6px;
  -webkit-transform-style: preserve-3d;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 2em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 2em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 2em 0px var(--glow-color);
}

.card-back-profile {
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: absolute;
    border-radius: 6px;
    -webkit-transform-style: preserve-3d;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 2em 0px var(--glow-color);
      -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 2em 0px var(--glow-color);
      box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 2em 0px var(--glow-color);
  }
.card-back-profile {
    transform: rotateY(180deg);
  }


@keyframes flicker {
  0%, 100% {
        box-shadow: 0 0 10px 3px var(--glow-color);
  }
  25%, 75% {
        box-shadow: 0 0 20px 5px var(--glow-color);
  }
  50% {
        box-shadow: 0 0 30px 8px var(--glow-color);
  }
    }