@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.contact-body{
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}
:root {
    --poppins-font: 'Poppins', sans-serif;
    --primary-color: #001f3f; 
    --secondary-color: #FF4136;
    --accent-color: #FF851B; 
    --background-color: #111111; 
    --text-color: #EEEEEE; 
    --glow-color: hsl(0, 75%, 45%);
}

.Contact {
    font-family: var(--poppins-font);
    color: var(--accent-color);
}

.container-contact {
    padding: 5rem;
    display: flex;
    margin-top: 200px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    height: 100vh;
    width: 100vw;
}

.about-list > li{
    list-style-type: circle;
    color: var(--text-color);
}

.message-input {
    flex: 1;
    display: flex;
    margin: 20px;
    min-width: 0; /* Ensure flexibility */
    height: 100px;
    padding: 1rem;
    font-family: var(--poppins-font);
    font-size: 20px;
    color: var(--text-color);
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    position: relative;
    z-index: 1;
}

.message-input:focus {
    outline: none;
}

.contact-button {
    color: var(--text-color);
    border: 1px solid var(--accent-color);
    cursor: pointer;
    margin-left: 2rem;
    font-weight: bold;
    font-size: 2rem;
    padding: .5rem;
    border-radius: 10px;
    transition: transform 0.3s ease, background-color 455ms ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;
    z-index: 2;
}

.message-sent {
    font-size: 3rem;
    margin-left: 2rem;
    margin-top: 5px;
}

.contact-button:hover {
    transform: scale(1.1);
    background-color: var(--glow-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
}